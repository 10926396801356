import { v4 } from 'uuid';
import lodashGet from 'lodash/get';
import lodashDebounce from 'lodash/debounce';
import { string } from 'yup';
import {
  fetchLendingAPIJSON,
  PostStateResponse,
  GetEmailAvailabilityResponse,
  PostStateRequestBody,
  fetchPostApplication,
  PostApplicationRequestBody,
} from '@iwoca/lapi-client/edge';

import { t } from '@lingui/macro';

import { postToDataLayer } from '../googleTracking';
import { FLEXI_LOAN_PRODUCT_NAME, ProductName } from '../products';
import { FormikProps } from 'formik';
import React from 'react';
import { DEFormValues } from './DEFormFields';
import { RecursiveNonNullable } from '../typeHelper';
import { API_AUTH_TOKEN } from '@iwoca/lapi-client/lendingAPI';

type UKLengthOfLoanValues =
  RecursiveNonNullable<PostStateRequestBody>['data']['application']['customer_expectations']['loan_duration'];

type DELengthOfLoanValues = 'short_term' | 'medium_term' | 'long_term';

export interface FormValues {
  applicantEmailAddress: string;
  requestedAmount: number | undefined;
  email_marketing_opt_in: boolean;
  no_amount_checkbox: boolean | undefined;
  lengthOfLoan: UKLengthOfLoanValues | DELengthOfLoanValues | undefined;
}

export async function submitSignupForm(
  signupValues: FormValues,
  product: ProductName,
  appVersion: string | undefined
) {
  // For the product agnostic modal, productName is set to null in useProductName to render the correct UI
  // We can't post null as a requested_product so default to Flexi-Loan
  if (!product) {
    product = FLEXI_LOAN_PRODUCT_NAME;
  }

  try {
    const signupDataWithoutProduct = serialiseStateWithoutProduct(signupValues);
    const postStateResponse = await fetch(`/api/lending/edge/state/`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        authorization: API_AUTH_TOKEN,
      },
      body: JSON.stringify(signupDataWithoutProduct),
    });
    const postStateData = (await postStateResponse.json()) as PostStateResponse;

    const stateKey = postStateData.data?.state_key as string;

    await fetchPostApplication({
      stateKey,
      body: serialiseApplicationEndpoint(signupValues),
    });
  } catch (error) {
    throw new Error(error as string);
  }
}

export async function sendApplicationDataToGA(
  values: FormValues | DEFormValues
) {
  const applicationData = {
    event: 'submitSignupStep0',
    applicationData: (({ applicantEmailAddress, ...userApplicationData }) =>
      userApplicationData)(values),
  };
  postToDataLayer(applicationData);
}

function serialiseApplicationEndpoint(
  values: FormValues | DEFormValues
): PostApplicationRequestBody {
  const productValues = getProductValues(values);
  return {
    data: {
      requested_products: [productValues],
    },
  };
}

export const debouncedEmailValidation = lodashDebounce(emailValidation, 750, {
  trailing: true,
});

export async function emailValidation(
  form: FormikProps<FormValues | DEFormValues>,
  event: React.ChangeEvent<HTMLInputElement>
) {
  // NOTICE: Errors get only displayed when touched

  // Loading required error messaged
  const errorMessages = {
    invalidEmail: t`Please enter a valid email address`,
    nonUniqueEmail: t`Email already taken - <a href="/login" data-ga-id="step0_email_already_exists__login">log in</a>`,
  };

  // Setup needed variables
  let errorMessage;
  let formStatus;
  const currentEmailInput = lodashGet(event, 'target.value');

  if (!currentEmailInput) return;
  if (currentEmailInput.length < 5) return; // Minimum email x@x.x

  // Validate if input matches email pattern
  const validEmail = await string().email().isValid(currentEmailInput);

  if (!validEmail) {
    errorMessage = errorMessages.invalidEmail;
    formStatus = 'EMAIL_INVALID';
  }

  // If input matches email pattern, run it against the API
  if (validEmail) {
    const uniqueEmail = await validateEmailUniqueness(currentEmailInput);

    if (!uniqueEmail) {
      errorMessage = errorMessages.nonUniqueEmail;
      formStatus = 'EMAIL_INVALID';
    }
  }

  // Use formStatus for additional visiual hints
  if (!formStatus) {
    formStatus = 'EMAIL_VALIDATED';
  }

  // Persist values to form
  form.setStatus(formStatus);
  form.setFieldError('applicantEmailAddress', errorMessage);
}
export async function validateEmailUniqueness(email: string) {
  if (!email) return true;

  try {
    const data = await fetchLendingAPIJSON<GetEmailAvailabilityResponse>(
      '/api/lending/edge/email_availability/',
      {
        query: { email },
        overrideHeaders: {
          authorization: API_AUTH_TOKEN,
        },
      }
    );
    const available = data.data.available;
    if (available) return true;
    return false;
  } catch (error: unknown) {
    // @ts-expect-error
    if (error?.data?.errors[0].code === 'URLQueryParameterError') {
      return true;
    }
    return false;
  }
}

function serialiseStateWithoutProduct(
  values: FormValues | DEFormValues
): PostStateRequestBody {
  return {
    data: {
      application: {
        customer_expectations:
          !values?.no_amount_checkbox &&
          values.lengthOfLoan &&
          !areDEFormValues(values)
            ? {
                loan_duration: values.lengthOfLoan as UKLengthOfLoanValues,
              }
            : undefined,
        people: [
          {
            last_name: '',
            uid: v4(),
            roles: ['applicant'],
            first_name: '',
            emails: [
              {
                email: values.applicantEmailAddress,
                marketing_opt_in: {
                  agreed: values.email_marketing_opt_in,
                },
                uid: v4(),
                type: 'primary',
              },
            ],
          },
        ],
      },
    },
  };
}

function getProductValues(values: FormValues | DEFormValues) {
  if (areDEFormValues(values)) {
    return {
      product_type: 'credit_facility' as const,
      amount: values.requestedAmount,
      purpose: values.purpose,
      detailed_purpose: values.detailed_purpose || undefined,
      duration: getDurationValue(values.lengthOfLoan),
    };
  } else {
    if (values.no_amount_checkbox) {
      return {};
    } else {
      return {
        product_type: 'credit_facility' as const,
        amount: values.requestedAmount as number,
        duration: getDurationValue(values.lengthOfLoan),
      };
    }
  }
}

export function getDurationValue(
  value: FormValues['lengthOfLoan']
):
  | RecursiveNonNullable<PostApplicationRequestBody>['data']['requested_products'][number]['duration']
  | undefined {
  if (value === 'few_months') {
    return { amount: 12, unit: 'months' };
  } else if (value === 'one_to_two_years') {
    return { amount: 24, unit: 'months' };
  } else if (value === 'three_plus_years') {
    return { amount: 60, unit: 'months' };
  } else if (value === 'ongoing') {
    return { amount: 12, unit: 'months' };
  } else if (value === 'short_term') {
    return { amount: 12, unit: 'months' };
  } else if (value === 'medium_term') {
    return { amount: 24, unit: 'months' };
  } else if (value === 'long_term') {
    return { amount: 60, unit: 'months' };
  }

  return undefined;
}

function areDEFormValues(
  values: FormValues | DEFormValues
): values is DEFormValues {
  return (values as DEFormValues).purpose !== undefined;
}
