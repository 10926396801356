import { Field, FieldProps } from 'formik';
import { FormValues } from './form';
import FormErrorMessage from './FormErrorMessage';
import { DEFormSchema } from './formSchema';

import DEstyles from './DEFormFields.module.css';
import styles from './FormFields.module.css';
import { PostApplicationRequestBody } from '@iwoca/lapi-client/edge';
import { RecursiveNonNullable } from '../typeHelper';
import { Input, Select } from '@iwoca/orion';

export interface DEFormValues extends FormValues {
  purpose?:
    | RecursiveNonNullable<PostApplicationRequestBody>['data']['requested_products'][number]['purpose'];
  detailed_purpose: string;
}

export const DEInitialValues: DEFormValues = {
  applicantEmailAddress: '',
  requestedAmount: undefined,
  purpose: undefined,
  detailed_purpose: '',
  email_marketing_opt_in: false,
  lengthOfLoan: undefined,
  no_amount_checkbox: undefined,
};

export const DEFormSettings = {
  initialValues: DEInitialValues,
  validationSchema: DEFormSchema(),
};

export const PersonalProcessingAgreement = () => {
  return (
    <p
      className={DEstyles.personalProcessingAgreement}
      data-testid="personal-processing-agreement"
    >
      Hiermit willige ich in die Verarbeitung meiner personenbezogenen Daten
      gemäß unserer aktuellen{' '}
      <a
        href="https://www.iwoca.de/datenschutzhinweis/"
        target="_blank"
        rel="noreferrer"
      >
        Datenschutzerklärung
      </a>{' '}
      der iwoca GmbH ein. Die erteilte Einwilligung kann jederzeit widerrufen
      werden. Mit den{' '}
      <a
        href="https://www.iwoca.de/allgemeine_geschaeftsbedingungen/"
        target="_blank"
        rel="noreferrer"
      >
        AGB
      </a>{' '}
      der iwoca GmbH erkläre ich mich einverstanden.
    </p>
  );
};

export const PurposeField = () => {
  return (
    <div className={styles.formGroup}>
      <label htmlFor="purpose">Wofür wollen Sie den Kredit nutzen?</label>
      <div className={styles.input}>
        <Field name="purpose">
          {({ form, field }: FieldProps<any, DEFormValues>) => (
            <Select
              {...field}
              id="purpose"
              placeholder="Bitte auswählen"
              onChange={(e) => {
                form.handleChange(e);
                if (e.target.value !== 'other') {
                  form.setFieldValue('detailed_purpose', '');
                }
              }}
              className={`
                ${styles.select}
                ${
                  form.errors.purpose &&
                  form.touched.purpose &&
                  styles.inputError
                }
              `}
            >
              <option value="stock_purchase">Warenbestand erhöhen</option>
              <option value="bridging_loan">Zwischenfinanzierung</option>
              <option value="marketing">Marketing betreiben</option>
              <option value="equipment_purchase">Maschinen erwerben</option>
              <option value="pay_bill">Verbindlichkeiten begleichen</option>
              <option value="refinancing_debt">Kredite umschulden</option>
              <option value="other">Sonstige Verwendung</option>
            </Select>
          )}
        </Field>
        <FormErrorMessage name="purpose" />
      </div>
    </div>
  );
};

export const LengthOfLoanField = () => {
  return (
    <div className={styles.formGroup}>
      <label htmlFor="lengthOfLoan">
        Für wie lange brauchen Sie den Kredit?
      </label>
      <div className={styles.input}>
        <Field name="lengthOfLoan">
          {({ form, field }: FieldProps<any, DEFormValues>) => (
            <Select
              {...field}
              id="lengthOfLoan"
              onChange={(e) => {
                form.handleChange(e);
              }}
              className={`
                ${styles.select}
                ${
                  form.errors.purpose &&
                  form.touched.purpose &&
                  styles.inputError
                }
              `}
              placeholder="Bitte auswählen"
            >
              <option value="short_term">
                Kurzfristig: nur ein paar Monate
              </option>
              <option value="medium_term">Mittelfristig: 1-2 Jahre</option>
            </Select>
          )}
        </Field>
        <FormErrorMessage name="lengthOfLoan" />
      </div>
    </div>
  );
};

export const DetailedPurposeField = () => {
  return (
    <div className={styles.formGroup}>
      <label htmlFor="detailed_purpose">Sonstiger Verwendungszweck</label>
      <div className={styles.input}>
        <Field name="detailed_purpose">
          {({ form, field }: FieldProps<any, DEFormValues>) => (
            <Input
              {...field}
              id="detailed_purpose"
              placeholder="Bitte geben Sie einen Verwendungszweck ein"
              className={`
                ${
                  form.errors.detailed_purpose &&
                  form.touched.detailed_purpose &&
                  styles.inputError
                }
              `}
            />
          )}
        </Field>
        <FormErrorMessage name="detailed_purpose" />
      </div>
    </div>
  );
};
